import React from 'react';
import PropTypes from "prop-types"

import Img from "gatsby-image"
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
// import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: '#f3f3f3',
    borderRadius: 0,
    paddingTop: 50,
    paddingBottom: 50,

    [theme.breakpoints.down('sm')]: {
      paddingTop: 10,
      paddingBottom: 0,
    },
  },
  categoryName: {
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  categoryDescription: {
    fontWeight: 'bold'
  },
  btnReadMore: {
    backgroundImage: 'linear-gradient(#da2721, #8b1b04)',
    color: '#fff'
  },
  description: {
    height: 200,

    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    }
  }
}));

const CategoryItem = ({ 
  data, 
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card 
      className={classes.root}
      elevation={0}
    >
      {data.categoryImage && (
        <Img 
          fluid={data.categoryImage.fluid} 
          alt={data.categoryImage.title} 
          style={{ minHeight: 120}} 
        />)
      }
      <CardContent>
        <Box
          height={100}
        >
          <Typography 
            gutterBottom 
            variant="h6" 
            component="p" 
            align="center"
            color="textSecondary"
            className={classes.categoryName}
          >
            {data.categoryName}
          </Typography>
        </Box>
        <Box
          className={classes.description}
        >
          <Typography 
            variant="body1"
            component="p" 
            align="center" 
            className={classes.categoryDescription}
          >
            {data.categoryDescription.categoryDescription}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          py={5}
        >
          <Button
            variant="contained"
            disableElevation
            size="large"
            className={classes.btnReadMore}
          >
            Read More
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

CategoryItem.propTypes = {
  data: PropTypes.object,
  // categoryImage: PropTypes.bool,
}

CategoryItem.defaultProps = {
  data: {},
}

export default CategoryItem
import React from 'react';
import PropTypes from "prop-types"

import Img from "gatsby-image"
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
// import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: '#f3f3f3',
    borderRadius: 0,
    border: `3px solid ${theme.palette.blue[900]}`
  },
  faqQuestion: {
    marginTop: 20,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: theme.palette.text.primary
  },
  faqAnswer: {
    fontWeight: 'bold',
    color: theme.palette.text.primary
  },
  btnReadMore: {
    background: '#231f20',
    color: theme.palette.blue[900],
    marginTop: 20,
    marginBottom: 20
  }
}));

const CategoryItem = ({ 
  data,
  questionHeight,
  contentHeight,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card 
      className={classes.root}
      elevation={0}
    >
      <CardContent>
        <Box
          className={questionHeight}
        >
          <Typography 
            gutterBottom 
            variant="h6" 
            component="p" 
            align="center"
            color="textSecondary"
            className={classes.faqQuestion}
          >
            {data.faqQuestion}
          </Typography>
        </Box>
        <Box
          className={contentHeight}
        >
          <Typography 
            variant="body1"
            component="p" 
            align="center" 
            className={classes.faqAnswer}
          >
            {data.faqAnswer.faqAnswer}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
        >
          <Button
            variant="contained"
            disableElevation
            className={classes.btnReadMore}
          >
            Read More
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

CategoryItem.propTypes = {
  data: PropTypes.object,
  // categoryImage: PropTypes.bool,
}

CategoryItem.defaultProps = {
  data: {},
}

export default CategoryItem
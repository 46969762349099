import React from 'react';
import { 
  useStaticQuery, 
  graphql 
} from "gatsby"
import Img from "gatsby-image";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import FaqItem from './faqItem';
import UnderlinedTitle from "../../underlinedTitle"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 50,
    paddingBottom: 50
  },
  contentHeight: {
    [theme.breakpoints.down('xl')]: {
      minHeight: 230
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 250
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto'
    }
  },
  contentHeightLarge: {
    [theme.breakpoints.down('xl')]: {
      minHeight: 130
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 250
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto'
    }
  },
  questionHeight: {
    [theme.breakpoints.down('xl')]: {
      minHeight: 100
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto',
      paddingTop: 10,
      paddingBottom: 10
    }
  },
  paintingManImage: {
    width: 400,
  }
}));

const Faq = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query FaqQuery {
      allContentfulFaq(sort: {order: ASC, fields: createdAt}) {
        edges {
          node {
            faqQuestion
            faqAnswer {
              faqAnswer
            }
          }
        }
      }
    }
  `)

  return (
    <Container
      className={classes.root}
    >
      <Box
        display="flex"
        justifyContent="center"
        pb={5}
      >
        <UnderlinedTitle
          variant="h2"
          title="Faq"
          uppercase={true}
          align="center"
          color="blue"
        />
      </Box>
      <Grid
        container
        direction="row"
        justify="space-between"
        className="page"
        spacing={5}
      >
        {
          data.allContentfulFaq.edges.map((item, index) => (
            <Grid
              key={item.node.faqQuestion}
              item 
              md={index < data.allContentfulFaq.edges.length - 2 ? 4 : 6}
            >
              <FaqItem
                data={item.node}
                questionHeight={classes.questionHeight}
                contentHeight={index < data.allContentfulFaq.edges.length - 2 ? classes.contentHeight : classes.contentHeightLarge}
              />
            </Grid>
          ))
        }
      </Grid>
    </Container>
  )
}

export default Faq
import React from "react"
// import { Link } from "gatsby"
// import { Link } from "gatsby-theme-material-ui";
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../layout/layout"
import SEO from "../seo/seo"
import Services from "../components/index/services"
import Slider from "../components/slider/slider"
import About from "../components/index/about"
import Faq from "../components/index/faq"
import Testimonial from "../components/index/testimonial"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title={`Home | ${data.site.siteMetadata.title}`} />
      <Slider />
      <Services />
      <About />
      <Faq />
      {/* <QualityWork />
      <WhatWeOffer />
      <OurWorks /> */}
      <Testimonial />
    </Layout>
  )
}

export default IndexPage

import React from 'react';
import { 
  useStaticQuery, 
  graphql 
} from "gatsby"
import Img from "gatsby-image"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  testimonialBackground: {
    position: "relative",
    height: 500,
    flexGrow: 1,
    height: 378,

    [theme.breakpoints.down('sm')]: {
      height: 570
    }
  },
  testimonialContainer: {
    position: 'absolute',
    zIndex: 1
  },
  testimonial: {
    paddingTop: 100,
    position: "relative",
  },
  testimonialContent: {
    color: theme.palette.default.main
  },
  testimonialOwnerName: {
    paddingTop: 30,
    textTransform: "uppercase",
    fontWeight: "bold",
    color: theme.palette.blue[900]
  }
}));

const Testimonial = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query TestimonialQuery {
      allContentfulTestimonial (sort: {order: ASC, fields: testimonialId}) {
        edges {
          node {
            testimonialId
            testimonialContent {
              testimonialContent
            }
            testimonialOwnerName
          }
        }
      }
      allContentfulAsset(filter: {title: {eq: "testimonial-background"}}) {
        edges {
          node {
            fluid (maxWidth: 1200) {
              ...GatsbyContentfulFluid_withWebp
            }
            title
            # description
          }	
        }
      }
    }
  `);

  return (
    <Box
      display="flex" 
      justifyContent="center" 
      flexGrow={1}
    >
      <Container
        className={classes.testimonialContainer}
      >
        <Grid 
          container 
          justify="center"
          alignItems="center"
          // spacing={5}
        >
          {
            data.allContentfulTestimonial.edges.map(testimonial => (
              <Grid
                key={testimonial.node.testimonialId}
                item
                xs={12}
                md={6}
                className={classes.testimonial}
              >
                <Typography
                  variant="h6"
                  component="p"
                  className={classes.testimonialContent}
                  align="center"
                >
                  {`"${testimonial.node.testimonialContent.testimonialContent}"`}
                </Typography>
                <Typography
                  variant="h5"
                  component="p"
                  align="center"
                  className={classes.testimonialOwnerName}
                >
                  {testimonial.node.testimonialOwnerName}
                </Typography>
              </Grid>
            ))
          }
        </Grid>
      </Container>
      <Img
        fluid={data.allContentfulAsset.edges[0].node.fluid}
        alt={data.allContentfulAsset.edges[0].node.title}
        className={classes.testimonialBackground}
      />
    </Box>
  )
}

export default Testimonial
import React from 'react';
import { 
  useStaticQuery, 
  graphql 
} from "gatsby"
import Img from "gatsby-image"
import clsx from 'clsx';
import Carousel from 'react-material-ui-carousel'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {},
  responsiveBox: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%"
    }
  },
  sliderImage: {
    position: "relative",

    [theme.breakpoints.down('sm')]: {
      height: 500
    }
  },
  // textShadow: {
  //   textShadow: "2px 4px 3px rgba(0,0,0,0.3)",
  // },
  sliderTitle: {
    fontWeight: 'bold',
  },
  sliderDescription: {
    [theme.breakpoints.down('md')]: {
      fontSize: '2.4rem',
    },
  },
  uppercase: {
    textTransform: 'uppercase'
  },
  btnEstimate: {
    color: '#fff',
    fontWeight: "bold",
    marginTop: 48,
    height: 60,
    width: 185,
    border: `3px solid ${theme.palette.text.secondary}`
  },
  btnEstimateOutline: {

  }
}));

const Slider = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query SiteSliderQuery {
      contentfulBrand {
        # companyName
        phoneNumber
      }
      allContentfulSlider {
        edges {
          node {
            sliderName
            sliderTitle
            sliderImage {
              fluid (maxWidth: 1200) {
                ...GatsbyContentfulFluid_withWebp
              }
              title
              # description
            }
            sliderDescription
          }
        }
      }
    }
  `)

  return (
    <Carousel className="slider">
      {
        data.allContentfulSlider.edges.map(item => (
          <Paper 
            key={item.node.sliderName} 
            elevation={0}
          >
            <Container>
              <Box 
                position="absolute" 
                display="flex" 
                flexDirection="column" 
                alignItems="flex-start" 
                justifyContent="center" 
                zIndex="1" flexGrow="1" 
                height="90%" 
                maxWidth="50%" 
                className={classes.responsiveBox}
              >
                <Typography 
                  variant="h6" 
                  color="textSecondary" 
                  className={clsx(classes.sliderTitle, classes.uppercase)}
                >
                  {item.node.sliderTitle}
                </Typography>
                <Typography 
                  variant="h1" 
                  color="secondary" 
                  component="h2" 
                  className={clsx(classes.sliderDescription, classes.uppercase)}
                >
                  {item.node.sliderDescription}
                </Typography>
                <Button 
                  href={"tel:" + data.contentfulBrand.phoneNumber} 
                  fullWidth={false} 
                  variant="outlined" 
                  disableElevation 
                  size="large" 
                  className={clsx(classes.btnEstimate, classes.uppercase)}
                >
                  Let's Talk
                </Button>
              </Box>
            </Container>
            <Img 
              fluid={item.node.sliderImage.fluid} 
              alt={item.node.sliderImage.title} 
              className={classes.sliderImage} 
            />
          </Paper>
        ))
      }
    </Carousel>
  )
}

export default Slider
import React from 'react';
import { 
  useStaticQuery, 
  graphql 
} from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import CategoryItem from './categoryItem';
import UnderlinedTitle from "../../underlinedTitle"

const useStyles = makeStyles((theme) => ({
  servicesContainer: {
    boxShadow: '0 39px 80px 24px #d5d6d7',
  },
  root: {
    paddingTop: 50,
    paddingBottom: 50
  },
  whatToExpect: {
    position: "absolute",
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.down('sm')]: {
      position: "relative"
    }
  },
  paintingManImage: {
    width: 400,
  }
}));

const Services = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query ServiceCategoryQuery {
      allContentfulServiceCategory(sort: {order: ASC, fields: createdAt}) {
        edges {
          node {
            categoryName
            categoryDescription {
              categoryDescription
            }
            categoryImage {
              fluid (maxWidth: 300) {
                ...GatsbyContentfulFluid_withWebp
              }
              title
              # description
            }
          }
        }
      }
    }
  `)

  return (
    <div
      className={classes.servicesContainer}
    >
      <Container
        className={classes.root}
      >
        <Box
          display="flex"
          justifyContent="center"
        >
          <UnderlinedTitle
            variant="h2"
            title="Services"
            uppercase={true}
            align="center"

          />
        </Box>
        <Grid
          container
          direction="row"
          justify="space-between"
          className="page"
          spacing={5}
        >
          {
            data.allContentfulServiceCategory.edges.map(item => (
              <Grid
                key={item.node.categoryName}
                item 
                xs={12}
                md={4}
                xl={4}
              >
                <CategoryItem
                  data={item.node}
                />
              </Grid>
            ))
          }
        </Grid>
      </Container>
    </div>
  )
}

export default Services